export const createResult = (props) => {
  const { data, loading } = props || {};
  return {
    data: data || {},
    success: '',
    error: '',
    fields: {},
    loading: loading != undefined ? loading : false,
  };
};
