import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { AppTheme, defaultTheme } from '@sdflc/ui';

import { localTheme, siteInfo } from '../../../config';

import { PageContent } from './styled';
import { Footer } from '../../Footer';

const HomeLayout = memo((props) => {
  const { children, title, description } = props;

  return (
    <AppTheme theme={localTheme}>
      <Helmet>
        <title>{title}</title>
        <meta name='description' content={description || siteInfo.description} />
        <style>{`
          html, body { font-family: Work Sans; }
        `}</style>
        {/* <meta http-equiv='X-UA-Compatible' content='IE=edge' />
        <meta charset='utf-8' />
        <meta http-equiv='Content-Type' content='text/html; charset=UTF-8' />
        <meta name='viewport' content='width=device-width, initial-scale=1, maximum-scale=1.0, user-scalable=no' />
        <link rel='canonical' href={siteInfo.canonical} />
        <link rel='icon' href={`${siteInfo.assetsBaserUrl}/favicon.ico`}></link>
        
        <link rel='preconnect' href='https://fonts.gstatic.com' crossorigin />
        <link
          href='https://fonts.googleapis.com/css2?family=Work+Sans:wght@200;300;400;500&display=swap'
          rel='stylesheet'
        />
        <script async src={`https://www.googletagmanager.com/gtag/js?id=${siteInfo.googleAnalyticsId}`}></script>
        <script type='application/ld+json'>{`
            {
            "@context": "http://schema.org",
            "@type": "WebSite",
            "name": "${siteInfo.siteName}",
            "url": "${siteInfo.canonical}"
            }
        `}</script>
        <meta property='og:type' content='website' />
        <meta property='og:site_name' content={siteInfo.siteName} />
        <meta property='og:url' content={siteInfo.canonical} />
        <meta property='og:title' content={siteInfo.title} />
        <meta property='og:image' content={siteInfo.image} />
        <meta property='og:description' content={siteInfo.description} />

        <meta name='twitter:card' content='summary_large_image' />
        <meta name='twitter:site' content={siteInfo.siteName} />
        {siteInfo.twitterHandle && <meta name='twitter:creator' content={siteInfo.twitterHandle} />}
        <meta name='twitter:title' content={siteInfo.title} />
        <meta name='twitter:description' content={siteInfo.description} />
        <meta name='twitter:image' content={siteInfo.image} />

        <meta name='rating' content='General' />
        <meta name='distribution' content='Global' />
        <meta name='author' content={siteInfo.author} />
        <meta name='site' content={siteInfo.siteName} />
        <meta name='classification' content={siteInfo.siteName} />

        <script>{`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
        
          gtag('config', '${siteInfo.googleAnalyticsId}');
        `}</script>
        <style>{`
          html, body { font-family: Work Sans; }
        `}</style> */}
      </Helmet>
      <PageContent>{children}</PageContent>
      <Footer />
    </AppTheme>
  );
});

HomeLayout.displayName = 'HomeLayout';

HomeLayout.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  children: PropTypes.node,
};

export { HomeLayout };
