import React, { memo, useState, useMemo } from 'react';
import validator from 'email-validator';
import PropTypes from 'prop-types';
import { createResult, submitVote } from '../../utils';
import {
  Alert,
  FormState,
  Form,
  FormFields,
  Input,
  Button,
  FormField,
  Label,
  FormButtons,
  ErrorText,
  Panel,
  Flex,
  TextArea,
  Centered,
  RadioButton,
  Row,
} from '@sdflc/ui';

const PollForm = memo((props) => {
  const [submitResult, setSubmitResult] = useState(createResult());

  const resultData = useMemo(() => {
    if (!submitResult.success) {
      return {};
    }

    const { data } = submitResult || {};
    const total = Object.keys(submitResult.data).reduce((acc, key) => {
      acc += submitResult.data[key];
      return acc;
    }, 0);

    if (!total) {
      return {
        shopper: 0,
        buyer: 0,
        both: 0,
      };
    }

    return {
      total,
      shopper: ((100 * data.SHOPPER) / total).toFixed(2),
      buyer: ((100 * data.BUYER) / total).toFixed(2),
      both: ((100 * data.BOTH) / total).toFixed(2),
    };
  }, [submitResult]);

  return (
    <FormState
      data={{
        vote: 'both',
      }}
      onSubmit={async (formData) => {
        setSubmitResult(createResult({ loading: true }));

        setTimeout(async () => {
          const result = await submitVote(formData);

          setSubmitResult({
            ...result,
            loading: false,
          });
        }, 500);
      }}
    >
      {({ data, handleChange, submit }) => {
        return (
          <Flex width={'100%'} mt={30}>
            <Centered>
              <Form width='auto'>
                {!submitResult.success && (
                  <FormFields>
                    <FormField>
                      <RadioButton
                        name='vote'
                        label='Be a buyer'
                        value={'shopper'}
                        checked={data.vote === 'shopper'}
                        onChange={handleChange}
                      />
                    </FormField>
                    <FormField>
                      <RadioButton
                        name='vote'
                        label='Be a shopper'
                        value={'buyer'}
                        checked={data.vote === 'buyer'}
                        onChange={handleChange}
                      />
                    </FormField>
                    <FormField>
                      <RadioButton
                        name='vote'
                        label='Be both a buyer and shopper, depending on the situation'
                        value={'both'}
                        checked={data.vote === 'both'}
                        onChange={handleChange}
                      />
                    </FormField>
                  </FormFields>
                )}
                <FormFields>
                  <FormField>
                    <Centered>
                      <Button
                        name='Submit'
                        variant='primary'
                        onClick={submit}
                        dimension='large'
                        hidden={submitResult.success}
                        progress={submitResult.loading}
                        mt={3}
                      />
                    </Centered>
                  </FormField>
                </FormFields>
                <FormFields>
                  <FormField>
                    {submitResult.success && (
                      <div>
                        <Row mb={1}>
                          <Panel width='140px'>Be a shopper: </Panel>
                          <Panel width='100px'>
                            <Panel width={`${resultData.shopper}%`} height='40px' backgroundColor='red' />
                          </Panel>
                          <Panel width='80px' textAlign='right'>{`${resultData.shopper}%`}</Panel>
                        </Row>
                        <Row mb={1}>
                          <Panel width='140px'>Be a shopper: </Panel>
                          <Panel width='100px'>
                            <Panel width={`${resultData.buyer}%`} height='40px' backgroundColor='green' />
                          </Panel>
                          <Panel width='80px' textAlign='right'>{`${resultData.buyer}%`}</Panel>
                        </Row>
                        <Row mb={1}>
                          <Panel width='140px'>Be both: </Panel>
                          <Panel width='100px'>
                            <Panel width={`${resultData.both}%`} height='40px' backgroundColor='blue' />
                          </Panel>
                          <Panel width='80px' textAlign='right'>{`${resultData.both}%`}</Panel>
                        </Row>
                      </div>
                    )}
                    {submitResult.error && <Alert variant='danger'>{submitResult.error}</Alert>}
                  </FormField>
                </FormFields>
              </Form>
            </Centered>
          </Flex>
        );
      }}
    </FormState>
  );
});

export { PollForm };
