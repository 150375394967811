import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { Container } from './styled';

const Centered = memo((props) => {
  const { children } = props;

  return <Container>{children}</Container>;
});

Centered.displayName = 'Centered';

Centered.propTypes = {};

Centered.defaultProps = {};

export { Centered };
