import axios from 'axios';
import qs from 'qs';
import { createResult } from './commonApiHelpers';

const processResponse = (response) => {
  const result = createResult();

  if (response.data.code === 0) {
    result['data'] = response.data.data[0];
    result['success'] = 'Thank you for your vote';
  } else {
    (response.data.errors || []).forEach((error) => {
      const msg = (error.errors || []).reduce((acc, err) => {
        acc += `${err} `;
        return acc;
      }, '');

      if (error.name === '') {
        result['error'] = msg;
      } else {
        result.fields[error.name] = msg;
      }
    });
  }

  return result;
};

export const submitVote = async (formData) => {
  const response = await axios.post(
    `https://proud-tree-9a23.9246401ci.workers.dev/landing/poll/vote/${formData.vote}`,
    qs.stringify(formData),
    {
      headers: {
        'content-type': 'application/x-www-form-urlencoded;charset=utf-8',
      },
    }
  );

  return processResponse(response);
};
