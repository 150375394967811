import axios from 'axios';
import qs from 'qs';
import dayjs from 'dayjs';
import { createResult } from './commonApiHelpers';

const processResponse = (response) => {
  const result = createResult();

  if (response.data.Code === 0) {
    const data = response.data.Data[0];
    result['success'] = data.Thank_You_Message || '';
  } else {
    const errors = response.data.Errors || {};

    Object.keys(errors).forEach((key) => {
      const msg = (errors[key].errors || []).reduce((acc, err) => {
        acc += `${err} `;
        return acc;
      }, '');

      if (key === '') {
        result['error'] = msg;
      } else {
        result.fields[key] = msg;
      }
    });
  }

  return result;
};

const preprocessData = (formData) => {
  const result = {};
  Object.keys(formData || {}).forEach((key) => {
    let value = formData[key];

    if (value instanceof Date) {
      value = dayjs(value).format('YYYY-MM-DD');
    }

    result[key] = value;
  });

  return result;
};

export const submitForm = async (formId, formData) => {
  const response = await axios.post(
    `https://api.formsubmits.com/forms/${formId}/submit`,
    qs.stringify(preprocessData(formData)),
    {
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'content-type': 'application/x-www-form-urlencoded;charset=utf-8',
      },
    }
  );

  return processResponse(response);
};
