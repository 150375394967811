import styled from 'styled-components';
import { colors } from '../../config';
import { breakpoints, mediaQueries, breakpointsSizes } from '@sdflc/ui';

const SuccessBlock = styled.div`
  display: flex;
  flex-direction: column;
  place-self: stretch;
  justify-content: center;
  width: 100%;
  background-color: ${colors.brightGreen};
  color: ${colors.white};
  padding: 30px;

  ${mediaQueries.md} {
    width: 50%;
  }
`;

SuccessBlock.displayName = 'SuccessBlock';

export { SuccessBlock };
