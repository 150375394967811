import { colors } from '../../config';
import { memo } from 'react';
import styled from 'styled-components';

const SubscribeButton = styled.a`
  padding: 20px;
  background-color: ${colors.brightGreen};
  color: ${colors.white};
  text-decoration: none;
  border-radius: 6px;
`;

export { SubscribeButton };
