import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Sticky, Header, HeaderItem, Navigation, NavigationLink } from '@sdflc/ui';

import { Logo } from '../';
import { navigationItems } from '../../config';

const AppHeader = memo((props) => {
  return (
    <Sticky>
      <Header>
        <HeaderItem>
          <a href='/'>
            <Logo />
          </a>
        </HeaderItem>
        <HeaderItem marginLeft='auto'>
          <Navigation direction='row'>
            {navigationItems.map((link) => {
              return (
                <NavigationLink
                  key={link.url}
                  linkProps={{ as: 'a', href: link.url }}
                  text={link.name}
                  icon={link.icon}
                />
              );
            })}
          </Navigation>
        </HeaderItem>
      </Header>
    </Sticky>
  );
});

AppHeader.displayName = 'AppHeader';

AppHeader.propTypes = {};

AppHeader.defaultProps = {};

export { AppHeader };
