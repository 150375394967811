import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Row } from '@sdflc/ui';
import { Logo } from '../Logo';

const Footer = memo((props) => {
  return (
    <footer>
      <Row justifyContent='center' mb={30} mt={30}>
        <Logo square={false} />
      </Row>
    </footer>
  );
});

Footer.displayName = 'Footer';

Footer.propTypes = {};

Footer.defaultProps = {};

export { Footer };
