import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

import { AppTheme, AspectRatioBox, Caption, Panel, Sticky, Header, HeaderItem } from '@sdflc/ui';

import { PageContent } from './styled';

import { AppHeader } from '../../';

import { siteInfo, localTheme } from '../../../config';

import { colors } from '../../../config/colors';

const DefaultLayout = memo((props) => {
  const { children, title, description } = props;
  const title2use = title ? `${siteInfo.title} - ${title}` : siteInfo.title;
  return (
    <AppTheme theme={localTheme}>
      <Helmet>
        <title>{title2use}</title>
        <link rel='icon' href={`${siteInfo.assetsBaserUrl}/favicon.ico`}></link>
        <meta name='description' content={description || siteInfo.description} />
        <link rel='preconnect' href='https://fonts.gstatic.com' />
        <link
          href='https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;1,300;1,400;1,600&display=swap'
          rel='stylesheet'
        />
        <script async src={`https://www.googletagmanager.com/gtag/js?id=${siteInfo.googleAnalyticsId}`}></script>
        <script>{`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
        
          gtag('config', '${siteInfo.googleAnalyticsId}');
        `}</script>
      </Helmet>
      <AppHeader />
      <AspectRatioBox
        ratio='FREE'
        minHeight='200px'
        centered
        backgroundColor='#fafafa'
        backgroundImage={`${siteInfo.assetsBaserUrl}/home-bg-003.jpg`}
      >
        <Panel backgroundColor={colors.headerBg} padding='30px' centered>
          <Caption level={1} noMargin>
            {title}
          </Caption>
        </Panel>
      </AspectRatioBox>
      <PageContent>{children}</PageContent>
    </AppTheme>
  );
});

DefaultLayout.displayName = 'DefaultLayout';

DefaultLayout.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  children: PropTypes.node,
};

export { DefaultLayout };
