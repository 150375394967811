export const siteInfo = {
  siteName: 'ShopaDropa',
  title: 'ShopaDropa',
  description: 'Connecting those who are in the shopping mood with those who are not',
  assetsBaserUrl: 'https://storage.googleapis.com/shopadropa-assets-bucket',
  googleAnalyticsId: 'G-LG3M86GBHQ',
  canonical: 'http://shopadropa.com',
  image: 'https://storage.googleapis.com/shopadropa-assets-bucket/shopadropa-social.jpg',
  twitterHandle: '',
  author: 'ShopaDropa',
  classification: 'shopping',
};
