import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Image } from '@sdflc/ui';

import { siteInfo } from '../../config';

const SIZES = {
  SMALL: 'small',
  MEDIUM: 'medium',
  LARGE: 'LARGE',
};

const size2px = {
  [SIZES.SMALL]: '30px',
  [SIZES.MEDIUM]: '100px',
  [SIZES.LARGE]: '200px',
};

const Logo = memo((props) => {
  const { onHomePage, square, darkBg, variant, size } = props;
  const height = size2px[size];
  const shape = square ? 'square' : 'rectangle';
  const bgColor = darkBg ? 'dark' : 'light';
  let src = '';

  if (onHomePage) {
    src = `${siteInfo.assetsBaserUrl}/home-page/home-logo.svg`;
  } else {
    src = `${siteInfo.assetsBaserUrl}/logo/logo_${shape}_${bgColor}_bg_${variant}.svg`;
  }

  return <Image src={src} style={{ height: height }} />;
});

Logo.displayName = 'Logo';

Logo.propTypes = {
  onHomePage: PropTypes.bool,
  square: PropTypes.bool,
  darkBg: PropTypes.bool,
  variant: PropTypes.oneOf(['blue', 'red', 'black']),
  size: PropTypes.oneOf(['small', 'medium', 'large']),
};

Logo.defaultProps = {
  onHomePage: false,
  square: true,
  darkBg: false,
  variant: 'red',
  size: 'medium',
};

export { Logo };
