import { defaultTheme } from '@sdflc/ui';
import { colors } from './colors';

export const localTheme = Object.assign({}, defaultTheme, {
  GlobalStyles: {
    fontSize: 3,
    lineHeight: 'normal',
    fontFamily: 0,
    margin: 0,
    padding: 0,
    minHeight: '100vh',
  },
  EmptyLayout: {
    fontSize: 3,
    lineHeight: 'normal',
    fontFamily: 0,
  },
  Header: {
    container: {
      backgroundColor: colors.headerBg,
      padding: 1,
    },
  },
  Caption: {
    h1: {
      fontSize: ['28px', '32px', '64px'],
      lineHeight: 'normal',
      color: '#fff',
    },
    h2: {
      fontSize: ['28px', '32px', '64px'],
      lineHeight: 'normal',
      textAlign: 'center',
    },
    h3: {
      fontSize: ['22px', '28px', '36px'],
      lineHeight: 'normal',
      textAlign: 'left',
      margin: '0',
    },
    h5: {
      fontSize: '16px',
      lineHeight: 'normal',
      color: '#13E800',
      margin: '0',
    },
    h6: {
      fontSize: '16px',
      lineHeight: 'normal',
      margin: '0',
    },
  },
  InputContainer: {
    ...defaultTheme.InputContainer,
    common: {
      ...defaultTheme.InputContainer.common,
      backgroundColor: colors.white,
      overflow: 'hidden',
      borderRadius: '6px',
      minHeight: '50px',
    },
    rightSide: {
      ...defaultTheme.InputContainer.rightSide,
      padding: '0',
    },
  },
  Input: {
    ...defaultTheme.Input,
    input: {
      ...defaultTheme.Input.input,
      common: {
        ...defaultTheme.Input.input.common,
        fontSize: '20px',
      },
    },
  },
  Button: {
    ...defaultTheme.Button,
    variants: {
      ...defaultTheme.Button.variants,
      primary: {
        ...defaultTheme.Button.variants.primary,
        backgroundColor: colors.brightGreen,
      },
    },
  },
});
