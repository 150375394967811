import React, { useState, useEffect } from 'react';
import { HomeLayout } from '../components/layouts';
import {
  Caption,
  AspectRatioBox,
  Image,
  Text,
  Section,
  Row,
  ItemsGroup,
  Panel,
  Grid,
  GridItem,
  Sticky,
  HiddenBelow,
  VisibleAbove,
  Flex,
  Curtain,
  Background,
  VisibleBelow,
  HiddenAbove,
} from '@sdflc/ui';
import { SubscribeForm, PollForm } from '../forms';
import { SubscribeButton, Centered, Logo, SuccessBlock } from '../components';
import { colors } from '../config/colors';
import { siteInfo } from '../config';
import { Fragment } from 'react';

const homeBgs = [
  `${siteInfo.assetsBaserUrl}/home-page/home-bg.jpg`,
  // `${siteInfo.assetsBaserUrl}/home-bg-001.jpg`,
  // `${siteInfo.assetsBaserUrl}/home-bg-002.jpg`,
  // `${siteInfo.assetsBaserUrl}/home-bg-003.jpg`,
  // `${siteInfo.assetsBaserUrl}/home-bg-004.jpg`,
];

const steps = [
  {
    title: 'Placing an order',
    text: 'Buyers dream up a list of what they want and from which store(s). They also enter information like their address, payment information, what they’re willing to pay, and even photos of their items.',
    image: `${siteInfo.assetsBaserUrl}/home-page/makingorder.svg`,
  },
  {
    title: 'Card pre-authorization',
    text: "Our system pre-authorizes a deposit amount from the buyer's credit card.",
    image: `${siteInfo.assetsBaserUrl}/home-page/systemauthorizes.svg`,
  },
  {
    title: 'Order is live',
    text: 'The order then appears on the list available for shoppers nearby.',
    image: `${siteInfo.assetsBaserUrl}/home-page/preparing.svg`,
  },
  {
    title: 'Shopper selects order',
    text: 'A nearby shopper picks an order for the store(s) where they like shopping.',
    image: `${siteInfo.assetsBaserUrl}/home-page/ordering.svg`,
  },
  {
    title: 'Communication',
    text: 'Our system allows shoppers to safely chat with their buyers about their items while shopping.',
    image: `${siteInfo.assetsBaserUrl}/home-page/chat.svg`,
  },
  {
    title: 'Purchase',
    text: 'Shoppers pay for the order themselves. When they upload the receipt, our system corrects the pre-authorized amount to match the receipt.',
    image: `${siteInfo.assetsBaserUrl}/home-page/receipt.svg`,
  },
  {
    title: 'Delivery',
    text: 'Shoppers deliver the order and upload a photo to prove delivery.',
    image: `${siteInfo.assetsBaserUrl}/home-page/map.svg`,
  },
  {
    title: 'Confirmation',
    text: 'The buyer confirms the delivery or notifies us about missing items with the amount they are willing to pay for them.',
    image: `${siteInfo.assetsBaserUrl}/home-page/confirmation.svg`,
  },
  {
    title: 'Buyer charged',
    text: 'Our system charges the buyer their agreed-upon amount and sends the full amount to the shopper. The shopper can then deliver missing items at their discretion.',
    image: `${siteInfo.assetsBaserUrl}/home-page/charge-buyer.svg`,
  },
  {
    title: 'Order completion',
    text: "That's it, order complete!",
    image: `${siteInfo.assetsBaserUrl}/home-page/transfer-money-to-shopper.svg`,
  },
];

// markup
const IndexPage = () => {
  const renderOddStep = (step, idx) => {
    return (
      <Fragment>
        <GridItem width={['100%', '100%', '50%', '50%']}>
          <Centered>
            <Image src={step.image} maxWidth='100%' width='100%' />
          </Centered>
        </GridItem>

        <GridItem width={['100%', '100%', '50%', '50%']}>
          <Caption level={5}>Step {idx + 1}</Caption>
          <Caption level={3} margin={['10px 0', '10px 0', '20px 0']}>
            {step.title}
          </Caption>
          <Text>{step.text}</Text>
        </GridItem>
      </Fragment>
    );
  };

  const renderEvenStep = (step, idx) => {
    return (
      <Fragment>
        <GridItem width='100%'>
          <HiddenAbove breakpoint='md'>
            <Image src={step.image} width='100%' />
          </HiddenAbove>
        </GridItem>
        <GridItem width={['100%', '100%', '50%', '50%']}>
          <Caption level={5}>Step {idx + 1}</Caption>
          <Caption level={3} margin={['10px 0', '10px 0', '20px 0']}>
            {step.title}
          </Caption>
          <Text>{step.text}</Text>
        </GridItem>
        <GridItem width={['100%', '100%', '50%', '50%']}>
          <VisibleAbove breakpoint='md'>
            <Centered>
              <Image src={step.image} maxWidth='100%' />
            </Centered>
          </VisibleAbove>
        </GridItem>
      </Fragment>
    );
  };

  return (
    <HomeLayout title={siteInfo.title}>
      <AspectRatioBox ratio='FREE' minHeight='100vh' centered backgroundColor='#333' backgroundImage={homeBgs[0]}>
        <Curtain opacity={0.6} backgroundColor='#000' mixBlendMode='unset' />
        <Panel padding='20px' maxWidth='80%' zIndex={2}>
          <Centered>
            <Logo onHomePage />
          </Centered>
          <Centered>
            <Caption level={1} noMargin textAlign='center'>
              Connecting those
              <br />
              who love to <span style={{ color: colors.brightGreen }}>shop</span>
              <br />
              with those who don’t (or can’t)
            </Caption>
          </Centered>
          <ItemsGroup justifyContent='center' padding={2}>
            <SubscribeButton href='#subscribe'>Subscribe</SubscribeButton>
          </ItemsGroup>
        </Panel>
      </AspectRatioBox>

      <Section dimension='fluid' variant='default'>
        <Panel maxWidth='90%' width='1000px' margin='0 auto'>
          <Centered>
            <Caption level={2}>How It Works</Caption>
          </Centered>
          <Grid>
            {steps.map((step, idx) => {
              if (idx % 2 === 0) {
                return renderOddStep(step, idx);
              }

              return renderEvenStep(step, idx);
            })}
            <GridItem width={['100%', '100%', '100%', '100%']}>
              <Panel borderRadius={30} mt={100} mb={100}>
                <Row wrapMode='wrap'>
                  <Image
                    src={`${siteInfo.assetsBaserUrl}/home-page/success.jpg`}
                    width={['100%', '100%', '50%', '50%']}
                    maxWidth='100%'
                  />
                  <SuccessBlock>
                    <Caption level={6}>Success</Caption>
                    <Caption level={3} noMargin>
                      Everyone is happy!
                    </Caption>
                  </SuccessBlock>
                </Row>
              </Panel>
            </GridItem>
          </Grid>
        </Panel>
      </Section>
      <Section dimension='fluid' variant='even'>
        <a id='poll' />
        <Centered>
          <Caption level={2} textAlign='center'>
            Poll
          </Caption>
          <Text>If I use your platform, I most likely will:</Text>
          <PollForm />
        </Centered>
      </Section>
      <Section dimension='fluid' noPadding>
        <AspectRatioBox ratio='FREE' minHeight='500px' centered backgroundColor='#333' backgroundImage={homeBgs[0]}>
          <Curtain opacity={0.6} backgroundColor='#000' mixBlendMode='unset' />
          <Panel padding='20px' maxWidth={['100%', '100%', '80%']} zIndex={2}>
            <a id='subscribe' />
            <Centered>
              <Caption level={2} noMargin textAlign='center' color={colors.white}>
                Subscribe
                <br />
                to receive <span style={{ color: '#13E800' }}>updates</span>
              </Caption>
              <SubscribeForm />
            </Centered>
          </Panel>
        </AspectRatioBox>
      </Section>
    </HomeLayout>
  );
};

export default IndexPage;
